exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-pt-pt-js": () => import("./../../../src/pages/contacto.pt-pt.js" /* webpackChunkName: "component---src-pages-contacto-pt-pt-js" */),
  "component---src-pages-contato-pt-br-js": () => import("./../../../src/pages/contato.pt-br.js" /* webpackChunkName: "component---src-pages-contato-pt-br-js" */),
  "component---src-pages-espaco-pt-br-js": () => import("./../../../src/pages/espaco.pt-br.js" /* webpackChunkName: "component---src-pages-espaco-pt-br-js" */),
  "component---src-pages-espacos-espaco-amparo-pt-pt-js": () => import("./../../../src/pages/espacos/espaco-amparo.pt-pt.js" /* webpackChunkName: "component---src-pages-espacos-espaco-amparo-pt-pt-js" */),
  "component---src-pages-espacos-espaco-efugio-pt-pt-js": () => import("./../../../src/pages/espacos/espaco-efugio.pt-pt.js" /* webpackChunkName: "component---src-pages-espacos-espaco-efugio-pt-pt-js" */),
  "component---src-pages-espacos-espaco-egide-pt-pt-js": () => import("./../../../src/pages/espacos/espaco-egide.pt-pt.js" /* webpackChunkName: "component---src-pages-espacos-espaco-egide-pt-pt-js" */),
  "component---src-pages-espacos-espaco-guarida-pt-pt-js": () => import("./../../../src/pages/espacos/espaco-guarida.pt-pt.js" /* webpackChunkName: "component---src-pages-espacos-espaco-guarida-pt-pt-js" */),
  "component---src-pages-espacos-pt-pt-js": () => import("./../../../src/pages/espacos.pt-pt.js" /* webpackChunkName: "component---src-pages-espacos-pt-pt-js" */),
  "component---src-pages-espacos-rececao-pt-pt-js": () => import("./../../../src/pages/espacos/rececao.pt-pt.js" /* webpackChunkName: "component---src-pages-espacos-rececao-pt-pt-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-br-js": () => import("./../../../src/pages/index.pt-br.js" /* webpackChunkName: "component---src-pages-index-pt-br-js" */),
  "component---src-pages-index-pt-pt-js": () => import("./../../../src/pages/index.pt-pt.js" /* webpackChunkName: "component---src-pages-index-pt-pt-js" */),
  "component---src-pages-mensagem-enviada-pt-br-js": () => import("./../../../src/pages/mensagem-enviada.pt-br.js" /* webpackChunkName: "component---src-pages-mensagem-enviada-pt-br-js" */),
  "component---src-pages-mensagem-enviada-pt-pt-js": () => import("./../../../src/pages/mensagem-enviada.pt-pt.js" /* webpackChunkName: "component---src-pages-mensagem-enviada-pt-pt-js" */),
  "component---src-pages-precos-pt-br-js": () => import("./../../../src/pages/precos.pt-br.js" /* webpackChunkName: "component---src-pages-precos-pt-br-js" */),
  "component---src-pages-precos-pt-pt-js": () => import("./../../../src/pages/precos.pt-pt.js" /* webpackChunkName: "component---src-pages-precos-pt-pt-js" */)
}

